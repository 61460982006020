import React, { useEffect, useState } from 'react';
import {
  typeList,
  copyrightList,
  statusList,
  nameList,
  styleList,
} from './searchOption-component';
import ScrollToTopButton from './ScrollToTopButton-component';
import { useNavigate, generatePath } from 'react-router-dom';
import SourceService from '../services/source.service';
import '../styles/style.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {
  Table,
  Input,
  Form,
  Modal,
  Select,
  Button,
  AutoComplete,
  Space,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { TabTitle } from './changeTitle';
import SourceWindow from './sourcewindow-componentN';

const { Option } = Select;
const { TextArea } = Input;

// import EachTable from "./eachTable-component";

const SourceComponent = (props) => {
  let { currentUser, setCurrentUser } = props;
  const history = useNavigate();
  const handleTakeToLogin = () => {
    history('/login');
  };
  const handle401 = (err) => {
    if (err.response.status === 401) {
      const modal = Modal.confirm({
        title: '權限已失效',
        content: `權限已失效，請重新登入。`,
        okText: '登入',
        cancelText: '取消',
        onOk: () => {
          modal.destroy();
          history('/login');
        },
        onCancel: () => {
          history('/');
        },
      });
    }
  };
  //顯示資料
  let [sourceData, setSourceData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [currentFilters, setCurrentFilters] = useState(null); // 用來儲存目前的篩選條件

  let [loading, setLoading] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  let [auth, setAuth] = useState(false);
  useEffect(() => {
    TabTitle('語料目錄');

    if (
      currentUser.user.role === 'staff' ||
      currentUser.user.role === 'admin'
    ) {
      setAuth(true);
      setLoading(true);
      // console.log("entering into SourceService.get()!!!!");
      SourceService.get()
        .then((res) => {
          // console.log(res.data);
          setSourceData(res.data);

          setFilteredData(res.data); // 初始化篩選資料
          setTotalCount(res.data.length); // 設定初始筆數
          //  enableBeforeUnload();
        })
        .catch((err) => {
          handle401(err);

          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (currentUser && currentUser.user.role === 'guest') {
      //訪客觀看已公開目錄
      SourceService.getPublic()
        .then((res) => {
          // console.log(res.data);
          setSourceData(res.data);
          setFilteredData(res.data); // 初始化篩選資料
          setTotalCount(res.data.length); // 設定初始筆數
          //disableBeforeUnload();
        })
        .catch((err) => {
          handle401(err);

          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      window.alert('沒有權限');
    }
  }, []);

  const [form] = Form.useForm();

  //是否有修改

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record._id === editingKey;
  //修改
  const edit = (record) => {
    console.log(`record: ${record.type} ${record._NO}`);
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record._id);
  };

  //儲存修改
  const save = async (record) => {
    try {
      const row = await form.validateFields();
      row._id = record._id;
      // const newSourceData = [...sourceData];
      //edit
      // console.log(`isNewData ${isNewData}`);
      SourceService.put(row)
        .then((res) => {
          // console.log(res.data);

          // window.alert("此筆紀錄已修改至資料庫");
          Modal.success({
            title: '修改成功',
            content: `此筆紀錄已修改至資料庫。`,
            okText: '確認',
          });
          const newdata = sourceData.map((u) => (u._id === row._id ? row : u));
          setSourceData(newdata);

          // 應用篩選條件到新的 sourceData
          applyFilters(newdata, currentFilters); // 重新篩選更新後的資料
          // window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data);
          Modal.error({
            title: '修改失敗',
            content: `此筆紀錄未修改至資料庫。`,
            okText: '確認',
          });
          // window.alert("此筆紀錄未被修改至資料庫");
          window.location.reload();
        });
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  //取消
  const cancel = (record) => {
    // new data
    // if (isNewData) {
    //   setIsNewData(false);
    //   window.location.reload();
    // }
    setEditingKey('');
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentFilters(filters); // 儲存當前篩選條件
    applyFilters(sourceData, filters); // 篩選資料
  };

  const applyFilters = (data, filters) => {
    let filtered = data;

    // 檢查 type 篩選條件
    if (filters && filters.type && Array.isArray(filters.type)) {
      filtered = filtered.filter((item) =>
        item.type ? filters.type.includes(item.type) : false
      );
    }
    // 檢查 style 篩選條件
    if (filters && filters.style && Array.isArray(filters.style)) {
      filtered = filtered.filter((item) =>
        item.style ? filters.style.includes(item.style) : false
      );
    }
    // 檢查 _NO 篩選條件
    if (filters && filters._NO && Array.isArray(filters._NO)) {
      filtered = filtered.filter((item) =>
        item._NO
          ? filters._NO.some((filterValue) =>
              String(item._NO)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            )
          : false
      );
    }

    // 檢查 author_title 篩選條件
    if (
      filters &&
      filters.author_title &&
      Array.isArray(filters.author_title)
    ) {
      filtered = filtered.filter((item) =>
        item.author_title
          ? filters.author_title.some((filterValue) =>
              String(item.author_title)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            )
          : false
      );
    }
    // 檢查 copyright 篩選條件
    if (filters && filters.copyright && Array.isArray(filters.copyright)) {
      filtered = filtered.filter((item) =>
        item.copyright ? filters.copyright.includes(item.copyright) : false
      );
    }
    // 檢查 status 篩選條件
    if (filters && filters.status && Array.isArray(filters.status)) {
      filtered = filtered.filter((item) =>
        item.status ? filters.status.includes(item.status) : false
      );
    }
    // 檢查 proofreader 篩選條件
    if (filters && filters.proofreader && Array.isArray(filters.proofreader)) {
      filtered = filtered.filter((item) =>
        item.proofreader
          ? filters.proofreader.includes(item.proofreader)
          : false
      );
    }
    // 檢查 tagger 篩選條件
    if (filters && filters.tagger && Array.isArray(filters.tagger)) {
      filtered = filtered.filter((item) =>
        item.tagger ? filters.tagger.includes(item.tagger) : false
      );
    }

    // 檢查 reviser 篩選條件
    if (filters && filters.reviser && Array.isArray(filters.reviser)) {
      filtered = filtered.filter((item) =>
        item.reviser ? filters.reviser.includes(item.reviser) : false
      );
    }
    setFilteredData(filtered); // 更新篩選後的資料
    setTotalCount(filtered.length); // 更新篩選後的筆數
  };
  //篩選文類
  const requiredRule = {
    required: true,
    message: `必填`,
  };
  let n = 0;

  //設定表格欄位
  const columns = [
    {
      title: '文類',
      dataIndex: 'type',
      width: '5vw',
      filters: typeList,
      filterMode: 'tree',
      onFilter: (value, record) => {
        // 檢查 record.type 是否存在再進行 includes 檢查
        return record.type ? record.type.includes(value) : false;
      },

      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='type' rules={[requiredRule]}>
            <Select initialValue='' style={{ fontSize: '20px' }}>
              {typeList.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <p style={{ width: '5vw' }}>{text}</p>
        );
      },
    },
    {
      title: '文體',
      dataIndex: 'style',
      width: '5vw',
      filters: styleList,
      filterMode: 'tree',

      onFilter: (value, record) => {
        // 檢查 record.style 是否存在
        return record.style ? record.style.includes(value) : false;
      },

      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='style'>
            <Select initialValue='' style={{ fontSize: '20px' }}>
              {styleList.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <p style={{ width: '5vw' }}>{text}</p>
        );
      },
    },
    {
      title: '編號',
      dataIndex: '_NO',
      align: 'center',
      width: '5vw',
      hidden: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              style={{ marginBottom: 8, display: 'block' }}
              autoFocus
              placeholder='輸入編號'
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Space>
              <Button
                onClick={() => {
                  confirm();
                }}
                type='primary'
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters();
                }}
                // type="danger"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record._NO.toLowerCase().includes(value.toLowerCase());
      },

      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='_NO' rules={[requiredRule]}>
            <Input style={{ fontSize: '20px' }} />
          </Form.Item>
        ) : (
          <p style={{ width: '5vw' }}>{text}</p>
        );
      },
    },
    //查詢篇名

    {
      title: '作者篇名',
      dataIndex: 'author_title',
      align: 'left',
      width: '15vw',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              style={{ marginBottom: 8, display: 'block' }}
              autoFocus
              placeholder='輸入文字'
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Space>
              <Button
                onClick={() => {
                  confirm();
                }}
                type='primary'
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters();
                }}
                // type="danger"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (val, rec) => {
        return rec.author_title.toLowerCase().includes(val.toLowerCase());
      },
      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='author_title' rules={[requiredRule]}>
            <TextArea rows={4} style={{ fontSize: '20px' }} />
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '出處',
      dataIndex: 'source',
      align: 'left',
      width: '15vw',
      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='source'>
            <TextArea rows={4} style={{ fontSize: '20px' }} />
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '授權',
      dataIndex: 'copyright',
      align: 'left',
      width: '5vw',
      hidden: true,
      filters: copyrightList,
      filterMode: 'tree',
      onFilter: (value, record) => record.copyright.includes(value),

      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item name='copyright' rules={[requiredRule]}>
            <Select initialValue='' style={{ fontSize: '20px' }}>
              {copyrightList.map((item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.text}
                  </Option>
                );
              })}
            </Select>
            {/* <AutoComplete
              options={optionsArray["copyrightList"]}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              style={{ fontSize: "20px" }}
            /> */}
          </Form.Item>
        ) : (
          <p style={{ width: '5vw' }}>{text}</p>
        );
      },
    },

    {
      title: '狀態',
      dataIndex: 'status',
      align: 'left',
      width: '5vw',
      hidden: true,
      filters: statusList,
      filterMode: 'tree',

      // filterSearch: true,
      onFilter: (value, record) => record.status.includes(value),
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item name='status' rules={[requiredRule]}>
            <Select initialValue='' style={{ fontSize: '20px' }}>
              {statusList.map((item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.text}
                  </Option>
                );
              })}
            </Select>
            {/* <AutoComplete
              options={optionsArray["statusList"]}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              style={{ fontSize: "20px" }}
            /> */}
          </Form.Item>
        ) : (
          <p style={{ width: '5vw' }}>{text}</p>
        );
      },
    },

    {
      title: '校對',
      dataIndex: 'proofreader',
      align: 'left',
      width: '5vw',
      hidden: true,
      filters: nameList,
      filterMode: 'tree',
      onFilter: (value, record) => {
        return record.proofreader ? record.proofreader.includes(value) : false;
      },
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item name='proofreader'>
            <AutoComplete
              options={nameList}
              style={{ fontSize: '20px' }}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </Form.Item>
        ) : (
          <p style={{ width: '5vw' }}>{text}</p>
        );
      },
    },
    {
      title: '標記',
      dataIndex: 'tagger',
      align: 'left',
      width: '5vw',
      hidden: true,
      filters: nameList,
      filterMode: 'tree',
      onFilter: (value, record) => {
        return record.tagger ? record.tagger.includes(value) : false;
      },
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item name='tagger'>
            <AutoComplete
              options={nameList}
              style={{ fontSize: '20px' }}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </Form.Item>
        ) : (
          <p style={{ width: '5vw' }}>{text}</p>
        );
      },
    },
    {
      title: '檢查',
      dataIndex: 'reviser',
      align: 'left',
      width: '5vw',
      hidden: true,
      filters: nameList,
      filterMode: 'tree',
      onFilter: (value, record) => {
        return record.reviser ? record.reviser.includes(value) : false;
      },
      render: (text, record) => {
        const editable = isEditing(record);
        // console.log(record.reviser)
        return editable ? (
          <Form.Item name='reviser'>
            <AutoComplete
              options={nameList}
              style={{ fontSize: '20px' }}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </Form.Item>
        ) : (
          <p style={{ width: '5vw' }}>{text}</p>
        );
      },
    },

    {
      title: '編輯選項',
      dataIndex: 'operation',
      width: '6vw',
      hidden: true,

      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div className='btn-group' role='group' style={{ width: '6vw' }}>
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={() => save(record)}
              style={{ width: 'auto', fontSize: '14px' }}
            >
              保存
            </button>

            <button
              type='button'
              className='btn btn-outline-primary btn-sm'
              onClick={cancel}
              style={{ width: 'auto', fontSize: '14px' }}
            >
              取消
            </button>
          </div>
        ) : (
          <div
            className='btn-group-vertical'
            role='group'
            style={{ width: 'auto', width: '6vw' }}
          >
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={() => edit(record)}
              style={{ width: 'auto', fontSize: '14px' }}
              // disabled={currentUser.user.role !== 'admin'}
            >
              修改文字
            </button>
            <button
              type='button'
              className='btn btn-dark btn-sm'
              onClick={() => goEachTable(record._NO)}
              style={{ width: 'auto', fontSize: '14px' }}
              // disabled={currentUser.user.role !== 'admin'}
              // onClick={gotoEachtable(record._NO)}
            >
              語料編輯
            </button>
            {/* <Button
              type="danger"
              onClick={() => onDelete(record)}
              style={{
                marginRight: 8,
                padding: 2,
              }}
            >
              刪除
            </Button> */}
            {/* <Button
              // disabled
              primary
              onClick={() => onAdd(record.order)}
              style={{
                padding: 2,
              }}
            >
              新增
            </Button> */}
          </div>
        );
      },
    },
  ];

  //點選前往各文本語料編輯頁面(另開新分頁)
  // const navigate = useNavigate();
  const goEachTable = (_NO) => {
    if (auth) {
      // console.log(generatePath("/eachTable/:_NO", { _NO }));
      let path = generatePath('/eachTable/:_NO', { _NO });
      const isProduction = process.env.NODE_ENV === 'production';
      const baseURL = isProduction
        ? process.env.REACT_APP_BACKEND_URL // 生产环境的 BASE URL
        : `https://localhost:3000`; // 开发环境的 BASE URL

      // navigate(path);

      window.open(baseURL + path, _NO);
    }
  };

  //訪客要隱藏編輯欄位
  let newColumns;
  if (auth) {
    newColumns = columns;
  } else {
    newColumns = columns.filter((item) => !item.hidden);
  }
  //取得下載的資料
  const [fileData, setFileData] = useState([]);
  //設定下載檔名加上日期
  let d = new Date();
  let dformat = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  let fileName = '語料目錄_' + dformat + '.xlsx';
  const handleDownload = async () => {
    // console.log("~~~~~~");
    // console.log(data);
    setFileData(sourceData);
    //取得下載的資料(新的)
    const selectedFields = sourceData.map((row) => ({
      // 最新標記: row.relation,
      文類: row.type,
      文體: row.style,
      編號: row._NO,
      作者篇名: row.author_title,
      出處: row.source,
      授權: row.copyright,
      狀態: row.status,
      校對者: row.proofreader,
      標記者: row.tagger,
      檢查者: row.reviser,
      原文: row.context,
    }));
    // 将数据转换为工作表
    const worksheet = XLSX.utils.json_to_sheet(selectedFields);

    // 创建工作簿并将工作表添加到其中
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // 生成 Excel 文件的二进制数据
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // 将生成的 Excel 文件保存到用户的本地存储
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  };
  return (
    <div style={{ padding: '1rem' }}>
      {!currentUser && (
        <div>
          <h6>必須先登入。</h6>
          <button
            onClick={handleTakeToLogin}
            className='btn btn-primary btn-sm'
          >
            登入
          </button>
        </div>
      )}
      {currentUser && (
        <div>
          {/* <br />
          <br /> */}
          <div className='position-relative d-flex flex-row '>
            <h3 className='display-4 fs-3 fw-bold'>語料目錄</h3>
            {/* 
            <ControllerButton></ControllerButton> */}
          </div>

          <hr />
        </div>
      )}
      {currentUser && auth && (
        <div
          className='blog__controller btn-group me-2 position-absolute end-0 d-flex flex-row'
          role='group'
        >
          <button
            className='btn btn-secondary'
            type='button'
            onClick={() => setUploadShow(true)}
          >
            新增語料目錄
          </button>
          <button className='btn btn-secondary' onClick={handleDownload}>
            下載語料目錄
          </button>
        </div>
      )}

      {uploadShow && (
        <SourceWindow /** 上傳視窗 */
          currentUser={currentUser}
          data={sourceData}
          show={uploadShow}
          onHide={() => setUploadShow(false)}
        />
      )}
      <br />
      <br />
      {currentUser && sourceData && sourceData.length !== 0 && (
        <div>
          {/* <h6>全部共有{sourceData.length}筆文本。</h6>
          <h6>目前篩選{n}筆文本。</h6> */}
          <div>
            {currentUser && auth && (
              <span
                class='input-group-text'
                style={{
                  width: '150px',
                }}
              >
                當前筆數: {totalCount}
              </span>
            )}
            <Form form={form} component={false}>
              <Table
                // rowSelection={rowSelection}
                loading={loading}
                columns={newColumns}
                dataSource={filteredData}
                pagination={{
                  position: ['topRight', 'bottomRight'],
                  defaultPageSize: 200,
                  showSizeChanger: true,
                  pageSizeOptions: ['50', '100', '200'],
                  total: sourceData.length,
                  // showTotal: (total) => `共${total}筆`,
                }}
                style={{
                  cursor: 'pointer',
                }}
                size='small'
                sticky
                onChange={handleTableChange}
                //點擊此列
                // onRow={(record, rowIndex) => {
                //   return {
                //     // onClick: () => {}, // click row
                //     onDoubleClick: () => {
                //       gotoEachtable(record._NO);
                //     }, // double click row
                //   };
                // }}
              ></Table>
            </Form>
            <ScrollToTopButton />
          </div>
        </div>
      )}
      {/* <AutoLogout currentUser={currentUser} setCurrentUser={setCurrentUser} /> */}
    </div>
  );
};

export default SourceComponent;
