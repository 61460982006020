import ControllerButton from './controllerbutton-component';
import ContextWindow from './context-component';
import KeywordWindow from './keyword-component';
import ScrollToTopButton from './ScrollToTopButton-component';
import { Button, Spinner } from 'react-bootstrap';

import {
  nameList,
  typeList,
  styleList,
  levelList,
  relationList,
  relationTree,
  syntaxList,
} from './searchOption-component';
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import {
  Table,
  Input,
  Switch,
  Form,
  Modal,
  Select,
  AutoComplete,
  TreeSelect,
} from 'antd';
import { TabTitle } from './changeTitle';
import { useNavigate, useParams } from 'react-router-dom';
import FuguService from '../services/fugu.service';
import SourceService from '../services/source.service';
import { format } from 'util';
// import RelationService from '../services/relation.service';

const { Option } = Select;
const { TextArea } = Input;
const { SHOW_PARENT, SHOW_CHILD } = TreeSelect;
const EachTable = (props) => {
  // const relationTree = convertToTree();
  let { currentUser, setCurrentUser } = props;
  const history = useNavigate();
  const handleTakeToLogin = () => {
    history('/login');
  };
  //取得資料
  const [data, setData] = useState([]);

  // const [relationData, setRelationData] = useState([]);

  const [loading, setLoading] = useState(false);
  // const [numAllNO, setNumAllNO] = useState(2222222222);
  const { _NO } = useParams();
  const handle401 = (err) => {
    if (err.response.status === 401) {
      const modal = Modal.confirm({
        title: '權限已失效',
        content: `權限已失效，請重新登入。`,
        okText: '登入',
        cancelText: '取消',
        onOk: () => {
          modal.destroy();
          history('/login');
        },
        onCancel: () => {
          history('/');
        },
      });
    }
  };
  let [auth, setAuth] = useState(false);
  let [isSorterEnabled, setIsSorterEnabled] = useState(false);

  useEffect(() => {
    //取得資料
    if (
      currentUser.user.role === 'staff' ||
      currentUser.user.role === 'admin'
    ) {
      setAuth(true);
      setLoading(true);
      if (_NO !== 'all') {
        TabTitle(`${_NO}`);

        SourceService.getSentence(_NO)
          .then((res) => {
            // console.log(res.data);
            setData(res.data);
            // setNumAllNO(222222222222);
            //enableBeforeUnload();
          })
          .catch((err) => {
            handle401(err);
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        TabTitle('語料編輯');
        setIsSorterEnabled(true);
        // FuguService.get()
        //   .then((res) => {
        //     // console.log(res.data);
        //     setData(res.data);
        //   })
        //   .catch((err) => {
        //     handle401(err);
        //     console.log(err);
        //   })
        //   .finally(() => {
        setLoading(false);
        //   });
      }
    } else if (currentUser && currentUser.user.role === 'guest') {
      TabTitle('語料檢索');
      // setLoading(true);

      // FuguService.getPublic()
      //   .then((res) => {
      //     // console.log(res.data);
      //     setData(res.data);
      //     // disableBeforeUnload();
      //   })
      //   .catch((err) => {
      //     handle401(err);
      //     console.log(err);
      //   })
      //   .finally(() => {
      setLoading(false);
      // });
    } else {
      window.alert('沒有權限');
    }
  }, []);

  //查找資料
  let [search, setSearch] = useState(false);
  let [searchK, setSearchK] = useState(false);
  let [sentencelevel, setSentencelevel] = useState([]);
  let [type, setType] = useState([]);
  // let [authorTitle, setAuthorTitle] = useState("");
  // let [text, setText] = useState("");
  let [formerkey, setFormerkey] = useState('');
  let [latterkey, setLatterkey] = useState('');
  let [former, setFormer] = useState('');
  let [latter, setLatter] = useState('');
  let [reviser, setReviser] = useState([]);
  let [tagger, setTagger] = useState([]);
  let [relation, setRelation] = useState([]);
  let [syntax, setSyntax] = useState([]);
  let [relationS, setRelationS] = useState('');
  let [style, setStyle] = useState([]);
  const resetBtn = () => {
    // console.log(v);
    setRelation([]);
    setSyntax([]);
    setRelationS('');
    setSentencelevel([]);
    setType([]);
    setStyle([]);
    setTagger([]);
    setReviser([]);
    setFormerkey('');
    setLatterkey('');
    setFormer('');
    setLatter('');
    setSearch(false);
  };
  const ChangeRelation = (v) => {
    // console.log(v);
    setRelation(v);
  };
  const ChangeSyntax = (v) => {
    // console.log(v);
    setSyntax(v);
  };
  const ChangeRelationS = (e) => {
    // console.log(v);
    setRelationS(e.target.value);
    setRelation([e.target.value]);
  };
  const ChangeSentencelevel = (v) => {
    // console.log(v);
    setSentencelevel(v);
  };
  const ChangeType = (v) => {
    // console.log(e.target.value);
    setType(v);
  };
  const ChangeStyle = (v) => {
    // console.log(e.target.value);
    setStyle(v);
  };
  const ChangeTagger = (v) => {
    setTagger(v);
  };
  const ChangeReviser = (v) => {
    setReviser(v);
  };
  // const ChangeText = (e) => {
  //   setText(e.target.value);
  // };
  const ChangeFormerkey = (e) => {
    // console.log(e.target.value);
    setFormerkey(e.target.value);
    setSearchK(false);
  };
  const ChangeLatterkey = (e) => {
    // console.log(e.target.value);
    setLatterkey(e.target.value);
    setSearchK(false);
  };
  const ChangeFormer = (e) => {
    // console.log(e.target.value);
    setFormer(e.target.value);
  };
  const ChangeLatter = (e) => {
    // console.log(e.target.value);
    setLatter(e.target.value);
  };

  const searchBtn = () => {
    // setSearch(true);
    setLoading(true);
    FuguService.search(
      relation,
      syntax,
      sentencelevel,
      type,
      style,
      tagger,
      reviser,
      formerkey,
      latterkey,
      former,
      latter,
      currentUser.user.role
    )
      .then((data) => {
        let key = countKeyword(data.data);
        Modal.success({
          title: '檢索完成',
          content: `共${data.data.length}筆，其中${key}筆有關聯詞，
          ${data.data.length - key}
          筆無關聯詞。`,
          okText: '確認',
        });
        setData(data.data);

        setSearch(true);
        if (formerkey.length > 0 || latterkey.length > 0) setSearchK(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 是否為新增資料,true為新增資料,false為修改資料
  const [form] = Form.useForm();

  const [isNewData, setIsNewData] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  //是否有修改
  const isEditing = (record) =>
    record.source === editingKey.source && record.order === editingKey.order;

  //修改
  const edit = (record) => {
    if (!record['note']) {
      record['note'] = '';
    }
    form.setFieldsValue({
      ...record,
    });
    setEditingKey({ order: record.order, source: record.source });

    setIsNewData(false);
  };

  //儲存修改
  const save = async (record) => {
    try {
      const row = await form.validateFields();
      row.order = record.order;
      if (isNewData) {
        //new data
        // console.log(`row: ${row.order}`);
        await FuguService.post(currentUser, row)
          .then((res) => {
            // console.log(res.data);
            // console.log(res.data.obj);
            // window.alert("此筆紀錄已新增至資料庫");
            Modal.success({
              title: '新增成功',
              content: `此筆紀錄已新增至資料庫。`,
              okText: '確認',
            });
            const newdata = data.map((u) =>
              u.order === row.order ? res.data.obj : u
            );
            setData(newdata);
            setEditingKey('');
            setIsNewData(false);
          })
          .catch((err) => {
            console.log(err.response.data);
            // window.alert(`此筆紀錄未被新增至資料庫,原因:${err.response.data}`);
            Modal.error({
              title: '新增失敗',
              content: `錯誤原因:${err.response.data}`,
              okText: '確認',
            });
            setEditingKey({ order: record.order, source: record.source }); // 恢复编辑状态
            // window.location.reload();
          });
        // setEditingKey('');
        // setIsNewData(false);
      } else {
        //edit
        row._id = record._id;
        // console.log(`isNewData ${isNewData}`);
        FuguService.put(row)
          .then((res) => {
            // console.log(res.data);
            Modal.success({
              title: '修改成功',
              content: `此筆紀錄已修改至資料庫。`,
              okText: '確認',
            });
            // window.alert("此筆紀錄已修改至資料庫");
            const newdata = data.map((u) => (u._id === row._id ? row : u));
            setData(newdata);
            // window.location.reload();
            setEditingKey('');
          })
          .catch((err) => {
            console.log(err.response.data);
            // window.alert("此筆紀錄未被修改至資料庫");
            Modal.error({
              title: '修改失敗',
              content: `錯誤原因:${err.response.data}`,
              okText: '確認',
            });
            // window.location.reload();
            setEditingKey({ order: record.order, source: record.source }); // 恢复编辑状态
          });
        // setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  //刪除單筆
  const onDelete = (record) => {
    // setData((pre) => {
    //   return pre.filter((data) => data.key != record.order);
    // });
    Modal.confirm({
      title: '確定刪除此筆資料?',
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        if (auth) {
          FuguService.delete(record._id)
            .then((res) => {
              Modal.success({
                title: '刪除成功',
                content: `此筆資料已從資料庫刪除。`,
                okText: '確認',
              });
              // window.alert("此筆紀錄已被刪除");
              // console.log(res.data);
            })
            .catch((err) => {
              Modal.error({
                title: '刪除失敗',
                content: `此筆資料未從資料庫刪除。`,
                okText: '確認',
              });
              console.log(err);
            });
          if (_NO === 'all') {
            searchBtn();
          } else {
            const reduced_data = data.filter(function (item) {
              return item._id !== record._id;
            });
            const newdata = reduced_data.map((item) => {
              if (item.order > record.order) {
                item.order -= 1;
                return item;
              } else {
                return item;
              }
            });
            // console.log(`newdata: ${JSON.stringify(newdata)}`);
            setData(newdata);
            // window.location.reload();
          }
        } else {
          window.alert('沒有權限');
        }
        // setData((pre) => {
        //   return pre.filter((data) => data.order !== record.order);
        // });
      },
    });
  };
  let [keynum, setkeynum] = useState(0);

  //統計有無關聯詞的標記數
  const countKeyword = (data) => {
    let num = 0;
    let Nnum = 0;
    data.map((item) => {
      if (
        item.formerkey === ' ' ||
        item.formerkey === '' ||
        item.formerkey === undefined
      ) {
        item.formerkey = '';
      }
      if (
        item.latterkey === ' ' ||
        item.latterkey === '' ||
        item.latterkey === undefined
      ) {
        item.latterkey = '';
      }
      if (item.formerkey !== '' || item.latterkey !== '') {
        num += 1;
      }
    });
    // num = data.length - Nnum;
    setkeynum(num);
    return num;
  };
  //取消動作
  const cancel = (record) => {
    console.log(`record: ${record}`);
    // new data
    if (isNewData) {
      let newdata = [...data];
      let i, idx;
      idx = newdata.findIndex((element) => element.order === record.order);
      console.log(`data: ${newdata.length}`);
      newdata.splice(idx, 1);
      for (i = idx; i < newdata.length; i++) {
        newdata[i].order--;
      }
      console.log(`newdata: ${JSON.stringify(newdata)}`);
      setData(newdata);
      setIsNewData(false);
    }
    setEditingKey('');
  };
  //新增一筆
  const onAdd = async (order) => {
    let i, idx;
    let newdata = [...data];
    idx = newdata.findIndex((element) => element.order === order);
    for (i = idx + 1; i < newdata.length; i++) {
      newdata[i].order++;
    }
    newdata.splice(idx + 1, 0, {
      order: order + 1,
      syntax: '',
      relation: '',
      sentencelevel: '',
      former: '',
      latter: '',
      formerkey: '',
      latterkey: '',
      source: data[0].source,
      tagger: '',
      reviser: '',
      note: '',
    });
    form.setFieldsValue({
      ...newdata[idx + 1],
    });
    // console.log(`newdata: ${JSON.stringify(newdata[idx + 1])}`);
    await setData(newdata);
    // setData(() => {
    //   return [...newdata];
    // });
    setEditingKey({ order: order + 1, source: data[0].source });
    // console.log(`editingKey: ${editingKey.order}`);
    setIsNewData(true);
    // setData((pre) => {
    //   return [...pre, newData];
    // });
  };
  // const pageChange = (pagination, filters, sorter) => {
  //   pagination.onChange(pagination.current, pagination.pageSize);
  // };

  //設定各欄位

  const requiredRule = {
    required: true,
    message: `必填`,
  };

  const columns = [
    {
      title: '句層',
      dataIndex: 'sentencelevel',
      width: '6vw',
      editable: true,

      sorter: isSorterEnabled
        ? (a, b) => a.sentencelevel.localeCompare(b.sentencelevel)
        : undefined,
      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='sentencelevel' rules={[requiredRule]}>
            <Select initialValue='' style={{ fontSize: '20px' }}>
              {levelList.map((item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.text}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '句法結構',
      dataIndex: 'syntax',
      width: '5vw',
      editable: true,
      sorter: isSorterEnabled
        ? (a, b) => {
            const syntaxA = a.syntax != null ? a.syntax.toString() : ''; // 处理 undefined 和 null
            const syntaxB = b.syntax != null ? b.syntax.toString() : ''; // 处理 undefined 和 null
            return syntaxA.localeCompare(syntaxB);
          }
        : undefined,
      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item
            name='syntax'
            // rules={[
            //   {
            //     required:
            //       record.sentencelevel === 'D' ||
            //       record.sentencelevel === 'DD' ||
            //       record.sentencelevel === 'CZ',
            //     message: '當句層為D,DD,CZ，句法結構必填',
            //   },
            // ]}
          >
            <Select initialValue='' style={{ fontSize: '20px' }}>
              {syntaxList.map((item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.text}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '語義關係',
      dataIndex: 'relation',
      width: '5vw',
      editable: true,
      sorter: isSorterEnabled
        ? (a, b) => {
            const relationA = a.relation != null ? a.relation.toString() : ''; // 处理 undefined 和 null
            const relationB = b.relation != null ? b.relation.toString() : ''; // 处理 undefined 和 null
            return relationA.localeCompare(relationB);
          }
        : undefined,
      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='relation'>
            <AutoComplete
              // options={getvaluechild(relationTree)}
              options={relationList}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              style={{ fontSize: '20px' }}
            />
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },

    {
      title: '前語段',
      dataIndex: 'former',
      width: '15vw',
      editable: true,
      align: 'left',
      sorter: isSorterEnabled
        ? (a, b) => a.former.localeCompare(b.former)
        : undefined,

      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='former' rules={[requiredRule]}>
            <TextArea rows={4} style={{ fontSize: '20px' }} />
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '後語段',
      dataIndex: 'latter',
      width: '15vw',
      editable: true,
      align: 'left',
      sorter: isSorterEnabled
        ? (a, b) => {
            const latterA = a.latter != null ? a.latter.toString() : ''; // 处理 undefined 和 null
            const latterB = b.latter != null ? b.latter.toString() : ''; // 处理 undefined 和 null
            return latterA.localeCompare(latterB);
          }
        : undefined,
      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='latter'>
            <TextArea rows={4} style={{ fontSize: '20px' }} />
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '前關聯詞',
      dataIndex: 'formerkey',
      width: '5vw',
      editable: true,
      sorter: isSorterEnabled
        ? (a, b) => a.formerkey.localeCompare(b.formerkey)
        : undefined,
      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='formerkey'>
            <TextArea rows={4} style={{ fontSize: '20px' }} />
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '後關聯詞',
      dataIndex: 'latterkey',
      width: '5vw',
      editable: true,

      sorter: isSorterEnabled
        ? (a, b) => a.latterkey.localeCompare(b.latterkey)
        : undefined,
      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='latterkey'>
            <TextArea rows={4} style={{ fontSize: '20px' }} />
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '出處',
      dataIndex: 'source',
      width: '10vw',
      editable: true,
      align: 'left',
      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item
            initialValue={record.source}
            name='source'
            rules={[requiredRule]}
          >
            <TextArea rows={4} style={{ fontSize: '20px' }} />
          </Form.Item>
        ) : (
          <>
            {!auth && <p>{text}</p>}
            {auth && _NO !== 'all' && <p>{text}</p>}
            {auth && _NO === 'all' && (
              <a class='stretched-link' onClick={() => showContext(text)}>
                {text}
              </a>
            )}
          </>
        );
      },
    },
    {
      title: '標記',
      dataIndex: 'tagger',
      width: '5vw',
      editable: true,
      hidden: true,

      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='tagger' rules={[requiredRule]}>
            <AutoComplete
              options={nameList}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              style={{ fontSize: '20px' }}
            />
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '檢查',
      dataIndex: 'reviser',
      width: '5vw',
      editable: true,
      hidden: true,

      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='reviser'>
            <AutoComplete
              options={nameList}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              style={{ fontSize: '20px' }}
            />
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '備註',
      dataIndex: 'note',
      width: '8vw',
      editable: true,
      hidden: true,

      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='note'>
            <TextArea rows={4} style={{ fontSize: '20px' }} />
          </Form.Item>
        ) : (
          <p>{text}</p>
        );
      },
    },
    {
      title: '編輯選項',
      dataIndex: 'operation',
      width: '13vw',
      hidden: true,

      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div className='btn-group' role='group'>
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={() => save(record)}
            >
              保存
            </button>

            <button
              type='button'
              className='btn btn-outline-primary btn-sm'
              onClick={() => cancel(record)}
            >
              取消
            </button>
          </div>
        ) : (
          <div className='btn-group' role='group'>
            <button
              type='button'
              className='btn btn-warning btn-sm '
              data-toggle='button'
              disabled={editingKey !== ''}
              // disabled={currentUser.user.role !== 'admin'}
              onClick={() => edit(record)}
            >
              修改
            </button>
            <button
              type='button'
              className='btn btn-danger btn-sm'
              data-toggle='button'
              onClick={() => onDelete(record)}
              // disabled={currentUser.user.role !== 'admin'}
            >
              刪除
            </button>
            {_NO !== 'all' && (
              <>
                <button
                  type='button'
                  className='btn btn-success btn-sm'
                  data-toggle='button'
                  onClick={() => onAdd(record.order)}
                  // disabled={currentUser.user.role !== 'admin'}
                >
                  新增
                </button>
              </>
            )}
          </div>
        );
      },
    },
  ];
  //開啟原文閱覽
  const [contextShow, setContextShow] = useState(false);
  const [authorTitle, setAuthorTitle] = useState(false);

  const showContext = (source) => {
    // console.log(source);
    setAuthorTitle(source);
    setContextShow(true);
  };
  const [keyin, setKeyin] = useState(true);

  //開啟關聯詞統計
  const [keywordShow, setKeywordShow] = useState(false);
  const [keyword, setKeyword] = useState('');
  const showKeyword = () => {
    if (searchK) {
      setKeyword(formerkey + '...' + latterkey);
      setKeywordShow(true);
    } else {
      Modal.error({
        title: '尚未送出檢索',
        content: `請先送出檢索才能顯示統計畫面。`,
        okText: '確認',
      });
    }
  };
  //訪客要隱藏編輯欄位
  let newColumns;
  if (auth) {
    newColumns = columns;
  } else {
    newColumns = columns.filter((item) => !item.hidden);
  }
  const onKey = (event) => {
    if (event.keyCode === 13) {
      searchBtn();
    }
  };

  return (
    <div style={{ padding: '1rem' }}>
      {!currentUser && (
        <div>
          <h6>必須先登入。</h6>
          <button
            onClick={handleTakeToLogin}
            className='btn btn-primary btn-sm'
          >
            登入
          </button>
        </div>
      )}

      {currentUser && (
        <div>
          <div>
            {auth && _NO !== 'all' && (
              <div className='position-relative d-flex flex-row '>
                <h3 className='display-4 fs-3 fw-bold'>{_NO}語料編輯</h3>
                <ControllerButton
                  key={data}
                  data={data}
                  currentUser={currentUser}
                />
              </div>
            )}
            {auth && _NO == 'all' && (
              <div className='position-relative d-flex flex-row '>
                <h3 className='display-4 fs-3 fw-bold'>全語料編輯</h3>
                {contextShow && (
                  <ContextWindow /** 原文視窗 */
                    currentUser={currentUser}
                    _NO={_NO}
                    authorTitle={authorTitle}
                    show={contextShow}
                    onHide={() => setContextShow(false)}
                  />
                )}
                {keywordShow && (
                  <KeywordWindow /** 原文視窗 */
                    currentUser={currentUser}
                    currentKeyword={keyword}
                    currentData={data}
                    show={keywordShow}
                    onHide={() => setKeywordShow(false)}
                  />
                )}
              </div>
            )}

            {currentUser && currentUser.user.role === 'guest' && (
              <div className='position-relative d-flex flex-row '>
                <h3 className='display-4 fs-3 fw-bold'>語料檢索</h3>
              </div>
            )}
          </div>
          <hr />
          {_NO === 'all' && (
            <div className='col row-cols-lg-auto g-3 align-items-center'>
              <div className='row row-cols-lg-auto g-3 align-items-center'>
                <div className='col-2'>
                  {keyin ? (
                    <TreeSelect
                      treeCheckable
                      showCheckedStrategy={TreeSelect.SHOW_CHILD}
                      size='large'
                      style={{ width: '100%', fontSize: '20px' }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeData={relationTree}
                      placeholder='語義關係...'
                      showSearch
                      allowClear
                      treeDefaultExpandAll
                      onChange={ChangeRelation}
                      value={relation}
                      onKeyDown={(e) => onKey(e)}
                    />
                  ) : (
                    <Input
                      type='relation'
                      style={{ fontSize: '20px' }}
                      placeholder='填入語義關係'
                      allowClear
                      value={relationS}
                      onChange={ChangeRelationS}
                      onKeyDown={(e) => onKey(e)}
                    />
                  )}
                </div>
                {auth && (
                  <Switch
                    checked={keyin}
                    checkedChildren='自選'
                    unCheckedChildren='自填'
                    onChange={() => {
                      setKeyin(!keyin);
                      setRelation(undefined);
                      setRelationS('');
                    }}
                  />
                )}
                <div className='col-2'>
                  <Select
                    mode='multiple'
                    size='large'
                    style={{ width: '100%', fontSize: '20px' }}
                    onChange={ChangeSyntax}
                    value={syntax}
                    placeholder='句法關係...'
                    allowClear
                    showSearch
                    onKeyDown={(e) => onKey(e)}
                  >
                    {syntaxList.map((option) => (
                      <Option style={{ fontSize: '20px' }} value={option.text}>
                        {option.text}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className='col-2'>
                  <Select
                    mode='multiple'
                    size='large'
                    style={{ width: '100%', fontSize: '20px' }}
                    onChange={ChangeSentencelevel}
                    value={sentencelevel}
                    placeholder='句層...'
                    allowClear
                    showSearch
                    onKeyDown={(e) => onKey(e)}
                  >
                    {levelList.map((item) => {
                      if (currentUser.user.role === 'guest') {
                        if (item.value !== 'J') {
                          return (
                            <Option key={item.value} value={item.value}>
                              {item.title}
                            </Option>
                          );
                        }
                      } else {
                        return (
                          <Option
                            style={{ fontSize: '20px' }}
                            key={item.value}
                            value={item.value}
                          >
                            {item.title}
                          </Option>
                        );
                      }
                    })}
                  </Select>
                </div>
              </div>
              <br />
              <div className='row row-cols-lg-auto g-3 align-items-center'>
                <div className='col-2'>
                  <Select
                    mode='multiple'
                    size='large'
                    style={{ width: '100%', fontSize: '20px' }}
                    onChange={ChangeType}
                    value={type}
                    placeholder='文類...'
                    allowClear
                    showSearch
                    onKeyDown={(e) => onKey(e)}
                  >
                    {typeList.map((option) => (
                      <Option style={{ fontSize: '20px' }} value={option.value}>
                        {option.text}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className='col-2'>
                  <Select
                    mode='multiple'
                    size='large'
                    style={{ width: '100%', fontSize: '20px' }}
                    onChange={ChangeStyle}
                    value={style}
                    placeholder='文體...'
                    allowClear
                    showSearch
                    onKeyDown={(e) => onKey(e)}
                  >
                    {styleList.map((option) => (
                      <Option style={{ fontSize: '20px' }} value={option.value}>
                        {option.text}
                      </Option>
                    ))}
                  </Select>
                </div>
                {auth && (
                  <div className='col-2'>
                    <Select
                      mode='multiple'
                      size='large'
                      style={{ width: '100%', fontSize: '20px' }}
                      onChange={ChangeTagger}
                      value={tagger}
                      placeholder='標記者...'
                      allowClear
                      showSearch
                      onKeyDown={(e) => onKey(e)}
                    >
                      {nameList.map((option) => (
                        <Option
                          style={{ fontSize: '20px' }}
                          value={option.value}
                        >
                          {option.text}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
                {auth && (
                  <div className='col-2'>
                    <Select
                      mode='multiple'
                      size='large'
                      style={{ width: '100%', fontSize: '20px' }}
                      onChange={ChangeReviser}
                      value={reviser}
                      placeholder='檢查者...'
                      allowClear
                      showSearch
                      onKeyDown={(e) => onKey(e)}
                    >
                      {nameList.map((option) => (
                        <Option
                          style={{ fontSize: '20px' }}
                          value={option.value}
                        >
                          {option.text}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
              <br />
              <div className='row row-cols-lg-auto g-3 align-items-center'>
                <div className='d-inline-flex '>
                  {/* <Input
                    addonBefore='前關聯詞'
                    type='text'
                    style={{ width: '20vw', fontSize: '20px' }}
                    onChange={ChangeFormerkey}
                    value={formerkey}
                    placeholder='請輸入一個關聯詞'
                    allowClear
                    onKeyDown={(e) => onKey(e)}
                  /> */}
                  <span class='input-group-text' id='inputGroup-sizing-default'>
                    前關聯詞
                  </span>
                  <input
                    type='text'
                    class='form-control'
                    // aria-label='Sizing example input'
                    aria-describedby='inputGroup-sizing-default'
                    onChange={ChangeFormerkey}
                    value={formerkey}
                    placeholder='請輸入一個關聯詞'
                    onKeyDown={(e) => onKey(e)}
                  ></input>
                </div>
                <div className='d-inline-flex '>
                  {/* <Input
                    addonBefore='後關聯詞'
                    value={latterkey}
                    type='text'
                    style={{ width: '20vw', fontSize: '20px' }}
                    onChange={ChangeLatterkey}
                    placeholder='請輸入一個關聯詞'
                    allowClear
                    onKeyDown={(e) => onKey(e)}
                  /> */}
                  <span class='input-group-text' id='inputGroup-sizing-default'>
                    後關聯詞
                  </span>
                  <input
                    type='text'
                    class='form-control'
                    // aria-label='Sizing example input'
                    aria-describedby='inputGroup-sizing-default'
                    onChange={ChangeLatterkey}
                    value={latterkey}
                    placeholder='請輸入一個關聯詞'
                    onKeyDown={(e) => onKey(e)}
                  ></input>
                </div>
                {auth && search && searchK && (
                  <Button
                    variant='btn btn-secondary'
                    onClick={() => showKeyword()}
                  >
                    關聯詞分布結果
                  </Button>
                )}
              </div>
              <br />
              <div className='row row-cols-lg-auto g-3 align-items-center'>
                <div className='d-inline-flex '>
                  {/* <Input
                    addonBefore='前語段'
                    type='text'
                    style={{ width: '20vw', fontSize: '20px' }}
                    onChange={ChangeFormer}
                    value={former}
                    placeholder='請輸入任意文字'
                    allowClear
                    onKeyDown={(e) => onKey(e)}
                  />  */}
                  <span class='input-group-text' id='inputGroup-sizing-default'>
                    前語段
                  </span>
                  <input
                    type='text'
                    class='form-control'
                    // aria-label='Sizing example input'
                    aria-describedby='inputGroup-sizing-default'
                    onChange={ChangeFormer}
                    value={former}
                    placeholder='請輸入任意文字'
                    onKeyDown={(e) => onKey(e)}
                  ></input>
                </div>
                <div className='d-inline-flex '>
                  <span class='input-group-text' id='inputGroup-sizing-default'>
                    後語段
                  </span>
                  <input
                    type='text'
                    class='form-control'
                    // aria-label='Sizing example input'
                    aria-describedby='inputGroup-sizing-default'
                    onChange={ChangeLatter}
                    value={latter}
                    placeholder='請輸入任意文字'
                    onKeyDown={(e) => onKey(e)}
                  ></input>
                  {/* <Input
                    addonBefore='後語段'
                    type='text'
                    style={{ width: '20vw', fontSize: '20px' }}
                    onChange={ChangeLatter}
                    value={latter}
                    placeholder='請輸入任意文字'
                    allowClear
                    onKeyDown={(e) => onKey(e)}
                  /> */}
                </div>
                <div className='d-inline-flex '>
                  <div>
                    {/* <button
                      type='submit'
                      className='btn btn-secondary'
                      onClick={searchBtn}
                      loading
                      // style={{ backgroundColor: "#ced4da" }}
                    >
                      送出檢索
                    </button> */}
                    {!loading && (
                      <button
                        type='submit'
                        className='btn btn-secondary'
                        onClick={searchBtn}
                      >
                        檢索
                      </button>
                    )}
                    {loading && (
                      <Button variant='dark' disabled>
                        <Spinner
                          as='span'
                          variant='light'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                          animation='border'
                        />
                        檢索中
                      </Button>
                    )}
                  </div>
                  <div style={{ marginLeft: '10px' }}>
                    <button
                      className='btn btn-outline-secondary'
                      onClick={resetBtn}
                      // style={{ backgroundColor: "#ced4da" }}
                    >
                      重設
                    </button>
                  </div>

                  <ControllerButton
                    key={data}
                    data={data}
                    currentUser={currentUser}
                  />
                </div>
              </div>
              <hr />
              {search && (
                <h6>
                  共{data.length}筆，其中{keynum}筆有關聯詞，
                  {data.length - keynum}
                  筆無關聯詞。
                </h6>
              )}
            </div>
          )}

          <Form form={form} component={false}>
            <Table
              loading={loading}
              // components={{
              //   body: {
              //     cell: EditableCell,
              //   },
              // }}

              bordered
              dataSource={data}
              columns={newColumns}
              rowClassName='editable-row'
              pagination={{
                position: ['topRight', 'bottomRight'],
                defaultPageSize: 200,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '400', '600'],
                // onChange: pageChange(),
                total: data.length,
                // total: _NO !== "all" ? data.length : numAllNO,
                // showTotal: (total) => `共${total}筆`,
              }}
              size='small'
              sticky
            />
          </Form>
          <ScrollToTopButton />
        </div>
      )}
      {/* <AutoLogout currentUser={currentUser} setCurrentUser={setCurrentUser} /> */}
    </div>
  );
};

export default EachTable;
